import React from "react"
import { graphql } from "gatsby"
import { Layout } from "../components/Layout"
import { SEO } from "../components/SEO"
import { PostCard } from "../components/PostCard"
import { AffiliateList } from "../components/AffiliateList"
import containerClasses from "../styles/container.module.css"

const Blog = ({ data }) => {
  const dataArr = data?.allMarkdownRemark?.edges
  const blogsDestructured = []

  dataArr.map(blog => {
    return blogsDestructured.push({
      ...blog.node.frontmatter,
    })
  })

  return (
    <Layout page="Blog">
      <SEO
        title="Blog |  Miss Hawkes | Commended Surgeon & Aesthetic Practitioner"
        description="Miss Hawkes shares expertise on ophthalmic and oculoplastic healthcare, and facial aesthetic treatments. Read the latest posts on the blog."
      />
      <div className="bg-white2">
        <div className={`${containerClasses.container} py-20`}>
          <div className="col-span-4 md:col-span-8 lg:col-span-8">
            <p className="mt-0 text-left overline">Blog Posts</p>
            <h1 className="mt-4 mb-0 text-left text-blackNew fluid-display-02">
              News and insights
            </h1>
          </div>
        </div>
      </div>
      <div className="border-solid bg-white2 border-borderColour border-t-1">
        <div className={`${containerClasses.container} py-8 lg:py-24`}>
          <div className="col-span-4 md:col-span-8 lg:col-span-12">
            {blogsDestructured.map(
              ({ title, category, path, date, image }, i) => (
                <PostCard
                  key={`post-card-${i}`}
                  title={title}
                  category={category}
                  path={path}
                  date={date}
                  image={image}
                />
              )
            )}
          </div>
        </div>
      </div>
      <AffiliateList />
    </Layout>
  )
}

export default Blog

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/blogs/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            layout
            title
            id
            category
            author
            path
            date
            excerpt
            image
          }
        }
      }
    }
  }
`
