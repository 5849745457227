import React from "react"
import { Layout } from "../components/Layout"
import { SEO } from "../components/SEO"
import { AffiliateList } from "../components/AffiliateList"
import aboutMain from "../assets/images/about-main.jpg"
import aestheticsJournalBlack from "../assets/images/aesthetic-journal-black.svg"
import cmac from "../assets/images/cmac-2022.png"
import profileBooksAtDeskArmsFolded from "../assets/images/elizabeth-profile.jpg"
import elizabethBio from "../assets/images/elizabeth-bio.png"
import containerClasses from "../styles/container.module.css"
import { CTABlock } from "../components/CTABlock"

const About = () => {
  return (
    <Layout page="About">
      <SEO
        title="About Miss Hawkes | Oculoplastic Surgeon & Aesthetic Doctor"
        description="Read more about Miss Elizabeth Hawkes – Consultant Ophthalmic and Oculoplastic Surgeon, practising at the award winning Cadogan Clinic, Sloane St, London."
      />
      <div className={`${containerClasses.container}`}>
        <div className="relative flex flex-col justify-center col-span-4 pt-8 lg:col-span-10 xl:col-span-8 xl:col-start-3 lg:col-start-2 md:col-span-6 md:col-start-2 lg:pt-20 md:pt-16">
          <p className="text-center overline">ABOUT</p>
          <h1 className="col-span-4 mt-0 mb-4 tracking-tighter text-center text-blackNew lg:col-start-3 fluid-display-02">
            Dr Elizabeth Hawkes MBBS BSc FRCOphth
          </h1>
        </div>
        <div className="block col-span-4 -mt-16 md:-mt-24 md:col-span-8 lg:col-span-8 lg:col-start-3">
          <img
            className="w-full"
            loading="lazy"
            alt="Dr Hawkes wearing an elegant cream suit with a ruffled blouse, standing against a plain background."
            src={aboutMain}
          />
        </div>
      </div>

      {/* Body text */}
      <div className="py-12 bg-white lg:py-16">
        <div className={`${containerClasses.container}`}>
          <div className="col-span-4 md:col-span-8 lg:col-span-8 lg:col-start-3">
            <h2 className="mt-0 fluid-heading-02">Education and Training</h2>
            <p className="mt-3">
              Miss Hawkes graduated in Medicine and Surgery from{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.imperial.ac.uk/"
              >
                Imperial College London
              </a>{" "}
              and has trained at{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://hms.harvard.edu/"
              >
                Harvard Medical School
              </a>
              , Boston. She holds a BSc degree in Neuroscience from Imperial
              College London. She undertook postgraduate medical and surgical
              training at{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.imperial.nhs.uk/our-locations/st-marys-hospital/"
              >
                St Mary&#39;s Hospital
              </a>
              , London before embarking on a career in Ophthalmic Surgery. She
              completed Ophthalmic Surgical Training on the prestigious Oxford
              rotation and spent time at{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.moorfields.nhs.uk/"
              >
                Moorfields Eye Hospital.
              </a>
            </p>
            <h2 className="mt-10 fluid-heading-02">
              Professional Appointments and Registrations
            </h2>
            <p className="mt-3 mb-0">
              Miss Hawkes is delighted to be taking over the care of Professor
              Richard Collin’s patients, after being mentored by him for several
              years. Professor Collin pioneered the specialty of Ophthalmic
              Plastic Surgery. Miss Hawkes is honored to be trusted with this
              prestigious role.
            </p>
            <p className="mt-3">
              Miss Hawkes held a substantive NHS consultant post where she was
              Clinical Lead of the Emergency Eye Care Service at the{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.royalberkshire.nhs.uk/our-services/eye-casualty/"
              >
                Royal Berkshire Hospital
              </a>{" "}
              NHS Foundation trust for 2 years.
            </p>
            <p>
              She is on the <a href="https://www.gmc-uk.org/">GMC</a> Specialist
              Register for Ophthalmic Surgery and was appointed as an NHS
              Consultant Ophthalmologist and Oculoplastic Surgeon in 2018.
            </p>
            <h2 className="mt-10 fluid-heading-02">
              Professional Memberships and Roles
            </h2>
            <p className="mt-3">
              Miss Hawkes is a Fellow and Examiner of the{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.rcophth.ac.uk/"
              >
                Royal College of Ophthalmologists
              </a>
              . She is a full member of the highly respected{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.esoprs.eu/"
              >
                European Society of Ophthalmic Plastic and Reconstructive
                Surgeons
              </a>{" "}
              and the{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.bopss.co.uk/"
              >
                British Oculoplastic Surgical Society
              </a>
            </p>
            <div className="flex flex-col items-center col-span-4 py-16 text-center md:py-16">
              <img
                className="inline w-12 h-12 mb-4"
                loading="lazy"
                alt="Headshot of Dr Elizabeth Hawkes wearing a navy top, framed within a circular border."
                src={elizabethBio}
              />
              <p className="mb-0 text-center fluid-quotation-01">
                “Having spent over 20 years training to become a{" "}
                <i>blepharoplasty specialist</i>, I have developed skills
                harnessing surgical and non-surgical bespoke treatments in order
                to restore the <i>natural</i> beauty of the eye and face.”
              </p>
              <p className="mt-4 mb-0 font-mono text-sm font-bold tracking-wider text-center text-gray-400 uppercase">
                – Dr Elizabeth Hakwes
              </p>
            </div>
            <h2 className="mt-0 fluid-heading-02">
              Advisory and Regulatory Roles
            </h2>
            <p className="mt-3">
              Miss Hawkes serves on the clinical advisory board for the
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://aestheticsjournal.com/"
              >
                {" "}
                Aesthetics Journal
              </a>{" "}
              , the UK&apos;s leading educational journal for aesthetic
              practitioners, where she plays a pivotal role in setting industry
              standards and provides key specialist ophthalmic perspectives
              regarding cosmetic eye treatments.
            </p>
            <p className="mt-3 mb-0">
              She is often consulted for second and even third opinions on
              complex aesthetic decision making by colleagues. She was appointed
              to the Specialist advisory board for the{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.cmac.world/"
              >
                Complications in Medical Aesthetics Collaborative Society
              </a>
              .
            </p>
            <div className="flex flex-row col-span-4 py-8 ">
              <div className="flex flex-col items-start flex-grow col-span-2 border-solid md:items-center md:border-r-1 md:border-l-1 md:border-borderColour">
                <img
                  className="h-8 mb-2"
                  loading="lazy"
                  alt="Aesthetics Journal Logo"
                  src={aestheticsJournalBlack}
                />
                <p className="mb-0 text-xs font-bold">
                  <b>Clinical Advisor</b>
                </p>
                <p className="text-xs">Aesthetics Journal</p>
              </div>
              <div className="flex flex-col items-start flex-grow col-span-2 md:items-center md:border-l-1 md:border-borderColour md:border-solid">
                <img
                  className="h-8 mb-2"
                  loading="lazy"
                  alt="Complications in Medical Aesthetics Collaborative logo"
                  src={cmac}
                />
                <p className="mb-0 text-xs font-bold">
                  <b>Specialist Advisor</b>
                </p>
                <p className="text-xs">CMAC</p>
              </div>
            </div>
            <h2 className="mt-0 fluid-heading-02">
              Clinical Practice and approach
            </h2>
            <p className="mt-3">
              Priding herself on achieving natural results, particularly in the
              realms of eye and facial rejuvenation, Miss Hawkes approaches each
              consultation with the understanding that every individual&apos;s
              anatomy and needs are unique. She invests time to thoroughly
              comprehend these unique needs, allowing her to meticulously tailor
              her treatment plans. This dedication to personalised care is a
              cornerstone of her practice, leading to consistently high patient
              satisfaction.
            </p>
            <p className="mt-3">
              Combining her passion for precise, delicate surgery with
              meticulous attention to detail, ophthalmology and cosmetic eyelid
              surgery were obvious career choices for Miss Hawkes. Her
              commitment to excellence is evident in her contributions to the
              medical community, having delivered several international and
              national presentations. She is also actively involved in surgical
              training, playing a significant role in educating the next
              generation of eye surgeons.
            </p>
            <p className="mt-3">
              Her professional yet friendly approach is highly valued by her
              patients, who range from high-profile individuals to professionals
              from various fields. This rapport, coupled with her expertise,
              underscores her reputation as a trusted specialist in her field.
            </p>
            <h2 className="mt-10 fluid-heading-02">
              Personal Background and Interests
            </h2>
            <p className="mt-3 mb-0">
              Miss Hawkes is of Iranian and British heritage and is an active
              member of the{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.iranianmedicalsociety.net/"
              >
                Iranian Medical Society
              </a>
              . She enjoys skiing, running and socialising with friends. To this
              day, Miss Hawkes continues to be inspired by her medical family.
              Her mother is a Consultant Ophthalmologist and her father is a
              world-renowned Professor of Neurology. She is married to an
              Ophthalmologist.
            </p>

            <p className="mt-3 mb-0">
              She consults on all aspects of general ophthalmology and is
              recognised with all the major insurance companies.
            </p>
          </div>
        </div>
      </div>
      <div className="py-8 bg-white lg:py-16 md:pt-24">
        <div
          className={`${containerClasses.container} flex flex-col lg:flex-row lg:items-center`}
        >
          <div className="flex flex-col justify-start col-span-4 mt-0 mb-4 lg:mb-0 lg:col-span-4 lg:col-start-3">
            <h2 className=" fluid-heading-02">
              Work with Professor Richard Collin
            </h2>
            <p className="mb-0">
              Miss Hawkes is delighted to be taking over the care of Professor
              Richard Collin’s patients, after being mentored by him for over 10
              years. Professor Collin pioneered the specialty of Ophthalmic
              Plastic Surgery. Miss Hawkes is honored to be trusted with this
              prestigious role.
            </p>
          </div>
          <img
            className="w-full col-span-4 lg:w-auto lg:col-span-3 lg:col-start-8"
            loading="lazy"
            alt="Portrait of Dr Elizabeth Hawkes wearing a navy blue medical uniform with her name and title 'Oculoplastic Surgeon' embroidered, standing against a light background."
            src={profileBooksAtDeskArmsFolded}
          />
        </div>
      </div>

      <CTABlock />
      <AffiliateList />
    </Layout>
  )
}

export default About
