import React from "react"
import { Carousel } from "../Carousel"

export const BeforeAndAfters = ({ beforeAndAfters }) => {
  return (
    <div className="py-20">
      <div className="col-span-4 md:col-span-8 lg:col-span-8 lg:col-start-3">
        <p className="my-0 overline">Dr Hawkes&apos; Results</p>
        <h2 className="fluid-heading-05 lg:text-left">Before & Afters</h2>
      </div>
      <div className="col-span-12 lg:col-span-8 lg:col-start-3">
        <Carousel>
          {beforeAndAfters.map(({ image }, index) => (
            <div
              key={index}
              className="flex flex-col h-full col-span-12 md:col-span-10 md:col-start-2 lg:col-start-4 lg:col-span-3"
            >
              <img
                src={image}
                alt="Before and after of Dr Elizabeth Hawes' previous patient"
              />
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  )
}
