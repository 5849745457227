import React from "react"
import { Link } from "gatsby"
import eye from "../../assets/icons/light-eye.svg"
import science from "../../assets/icons/light-science.svg"
import woman from "../../assets/icons/light-woman.svg"

type PostCardProps = {
  title: string
  category: string
  path: string
  image: string
  date: string
}

export const PostCard = ({
  title,
  category,
  path,
  image,
  date,
}: PostCardProps) => {
  const d = new Date(date)
  const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d)
  const mo = new Intl.DateTimeFormat("en", { month: "short" }).format(d)

  let catImg = eye

  if (category == "Aesthetics") {
    catImg = woman
  }

  if (category == "Eye Surgery") {
    catImg = science
  }

  if (category === "Eyes") {
    catImg = eye
  }

  return (
    <Link to={`${path}`}>
      <div className="grid grid-cols-12 col-span-4 row-gap-0 col-gap-6 mb-8">
        <div className="hidden w-24 col-span-12 mb-8 md:inline-block md:mb-0 md:w-auto md:col-span-2 lg:col-span-1">
          <div className="py-1 font-mono text-sm text-center text-white uppercase bg-blackNew">
            {mo}
          </div>
          <div className="py-3 font-mono text-sm text-center uppercase bg-white ">
            <span className="block mt-1 tracking-wider">{ye}</span>
          </div>
        </div>
        <div className="col-span-12 bg-white shadow-sm md:col-span-10 lg:col-span-11">
          <div className="flex flex-col lg:grid lg:grid-cols-11 lg:gap-6">
            <div className="relative lg:col-span-6">
              <img
                className="object-cover w-full h-full"
                loading="lazy"
                src={image}
                alt="Post"
              />
              <div className="absolute inline-block px-4 py-1 font-mono text-xs font-semibold tracking-wide text-white lg:hidden top-1 left-1 bg-gold600">
                <div className="flex items-center">
                  <span className="font-light tracking-wider uppercase">
                    {category}
                  </span>
                </div>
              </div>
            </div>
            <div className="px-8 pt-8 pb-16 md:px-10 md:pt-10 md:pb-20 sm:pb-40 lg:pl-6 lg:pr-18 lg:pt-12 lg:col-span-5">
              <h3 data-testid="postcardTitle" className="mt-4 fluid-heading-03">
                {title}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}
