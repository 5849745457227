import React from "react"
import { Link } from "gatsby"
import buttons from "../../styles/buttons.module.css"
import containerClasses from "../../styles/container.module.css"

export const CTABlock = () => {
  return (
    <div className="bg-white2">
      <div className={`${containerClasses.container} py-12 md:py-24 lg:py-32`}>
        <div className="col-span-4 py-16 bg-white border-t-4 border-b-4 border-solid lg:col-start-2 md:col-span-8 lg:col-span-10 md:py-24 lg:py-32 border-gold600">
          <div className="px-8 text-center lg:px-40 xl:px-80">
            <h2 className="mt-0 fluid-heading-05 text-blackNew">
              Get in Touch
            </h2>
            <p>
              Considering a procedure or have questions? Dr. Hawkes is here to
              help. Please reach out for consultations or inquiries.
            </p>
            <Link
              className={`inline-block mt-0 lg:self-start ${buttons.btnLargeExpressiveGold}`}
              to="/contact/"
            >
              <div className="flex items-center justify-between">
                Contact
                <span className="hidden md:inline-block">
                  &nbsp;Miss Hawkes
                </span>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
